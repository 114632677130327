<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="16">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.active_works")}}</div>
              <div class="block-sarche">
                <div class="header__search d-flex mobile__column">
                  <crm-input
                    :size="'small'"
                    class="mr0__mobile"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  <div class="mx-3 mr0__mobile space__input">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id"
                    ></select-staff>
                  </div>
                  <div class="mx-3 mr0__mobile space__input">
                    <el-date-picker
                      v-model="filterForm.from_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.from')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                  <div class="mx-3 mr0__mobile space__input">
                    <el-date-picker
                      v-model="filterForm.to_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.before')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :can_create="false"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>

           
              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>

              
              <th v-if="columns.type.show">
                  {{ columns.type.title }}
              </th>
              <th v-if="columns.is_show.show">
                {{ columns.is_show.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <!-- <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th> -->
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.staff_id.show">
                <select-staff
                v-model="filterForm.staff_id"
                :class="mode ? 'input__day' : 'input__night'"
                  :id="filterForm.staff_id"
                >
              </select-staff>
                
              </th>

             

              <th v-if="columns.comment.show">
                <crm-input
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>
              <th v-if="columns.type.show">
                <el-select
                          
                :placeholder="columns.type.title"
                class="d-block"
                v-model="filterForm.type"
                :class="mode ? 'filter__day' : 'filter__night'"
                 :size="'small'"
                 filterable
                 clearable
                 
              >
                <el-option
                  :label="$t('message.prosperous_place')"
                  :value="'prosperous_place'"
                ></el-option>
                <el-option
                :label="$t('message.active_person')"
                :value="'active_person'"
                ></el-option>
              </el-select>
              </th>
              <th v-if="columns.is_show.show">
                <crm-input
                  :placeholder="columns.is_show.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.is_show"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="complaint in list"
              :key="complaint.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ complaint.id }}</td>

              <td v-if="columns.staff_id.show">
                {{
                  complaint.staff
                    ? complaint.staff.name + " " + complaint.staff.last_name
                    : ""
                }}
              </td>
              <td v-if="columns.comment.show">
                {{ complaint.comment }}
              </td>
              <td v-if="columns.type.show">
                {{ $t("message."+complaint.type)  }}
              </td>
              <td v-if="columns.is_show.show">
                <div class="image__preview application_img" >
                  <el-image
                    class="my-c-avatar"
                    style="width: 50px; height: 50px"
                    :src="complaint.path ? baseUrl + complaint.path : 'img/avatar.jpeg'"
                    :preview-src-list="[
                      complaint.path ? baseUrl + complaint.path : 'img/avatar.jpeg',
                    ]"
                  >
                  </el-image>
                </div>
                <!-- <div v-if="complaint.media_type =='photo'">
                  <video-player :videoURL="complaint.path" />
                </div> -->
              </td>

              <td v-if="columns.created_at.show">
                {{ complaint.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ complaint.updated_at }}
              </td>

              <!-- <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="complaint"
                                    :actions="actions"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td> -->
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import VideoPlayer from "./components/VideoPlayer";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "SkillController",
  mixins: [list],

  data() {
    return {
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
    };
  },
  components: {
    VideoPlayer,
  },
  mounted() {
    this.updateList();
  },
  computed: {
    ...mapGetters({
      list: "activework/list",
      columns: "activework/columns",
      pagination: "activework/pagination",
      statues: "activework/statues",
      filter: "activework/filter",
      sort: "activework/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "activework/activework",
      setPagination: "activework/setPagination",
      updateSort: "activework/updateSort",
      updateFilter: "activework/updateFilter",
      updateColumn: "activework/updateColumn",
      updatePagination: "activework/updatePagination",
      show: "activework/show",
      empty: "activework/empty",
      delete: "activework/destroy",
      refreshData: "activework/refreshData",
    }),
  },
};
</script>
<style lang="scss" >
  .application_img{
    .my-c-avatar{
      height: 80px !important;
      width: 70px !important;
    }
  
    .my-c-avatar img{
      height: 80px !important;
      border-radius: 0;
    }
  }
</style>
